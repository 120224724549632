@import "../../styles/globals";

.mentions-modal{
    max-width: 600px;
    height: 500px;
    display: flex;
    flex-direction: column;

  .mentions{
    margin-bottom: 12px;
    height: fit-content;

    .mentions-row{
      width: -webkit-fill-available;
      height: 100%;
      display: flex;
    }

    .date{
      font-size: 0.8rem;
      padding-right: 8px;
      padding-left: 8px;
    }
  }

  .mention-item{
    width: initial;
    background: $secondary-background-color;
    border-radius: 8px;
    padding: 8px;
    .username{
      font-size: 0.8rem;
      font-weight: bold;
    }
  }

  .mention-right{
    display: flex;
    margin-right: 0;
    margin-left: auto;
    justify-content: right;
    max-width: 80%;


    .date,.username{
      text-align: end;
    }
  }

  .mention-left{
    width: fit-content;
    max-width: 80%;
  }
}