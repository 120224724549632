@import "../../../styles/globals";
.items-container .menu-info{
  font-weight: normal;

  input, input:focus,.selector__control ,.selector__control :focus {
    font-weight: normal;
  }
}

.menu-info{
  display: inline-flex;
  font-weight: bold;
  font-size: 0.95rem;

  width: -webkit-fill-available;
  .input-container {
    width: -webkit-fill-available;
  }
  input, input:focus,.selector__control ,.selector__control :focus {
  //  border: none;
//    outline: none;
    padding: 0 0.5rem;
    border: 1px solid $primary-border-color;
    border-radius: 8px;
    font-weight: bold;
    margin-left: 6px;
    margin-bottom: 4px;
    width: -webkit-fill-available;
    height: 1.3rem;


    &.price{
    width: 60px;
      justify-content: end;
      text-align: end;
  }
    .selector__indicators{
      display: none;
    }
  }
  .price-input-container{
    width: unset;
    input{
      width: 100px;
      padding-right: 40px;
      float: right;
    }
    p{
      top: -15px
    }

  }
  &.description{
    margin-bottom: 20px;
  }
  .title{
    width: -webkit-fill-available;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
  .fa-trash-can{
    cursor: pointer;
    color: $error-color;
  }
}
.items-container{
  padding-left: 40px;
}
.add-new-item{
  cursor: pointer;
  font-weight: bold;
  color: $primary-color;
}