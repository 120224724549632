@import "../../../styles/globals";

.Mui-focusVisible {
  padding: 0;

  &.MuiSwitch-switchBase {
    padding: 0;
    margin: 2px;
    transition-duration: 300ms;

    &.Mui-checked {
      transform: translateX(16px);
      color: $primary-white;
      &.MuiSwitch-track {
        background-color: $primary-color;
        opacity: 1;
        border: 0;
      }
      &.Mui-disabled + .MuiSwitch-track {
        opacity: 0.5;
      }
    }

    &.Mui-focusVisible .MuiSwitch-thumb {
      color: $primary-color;
      border: 6px solid $primary-white;
    }

    &.Mui-disabled .MuiSwitch-thumb {
      color: $gray-color-1
    }

    &.Mui-disabled + .MuiSwitch-track {
      opacity: 0.7
    }
  }
}


.MuiSwitch-thumb {
  box-sizing: border-box;
  width: 22px;
  height: 22px;
}

.MuiSwitch-track {
  border-radius: 13px;
  background-color: $primary-background-color;
  opacity: 1;
//  transition: theme . transitions . create(['background-color'];
/*{
  duration: 500ms;
}*/
}
.react-switch-bg svg{
  width: 70%;
  height: 70%;
  margin: 15%;
}