@import "../../../../styles/globals";

.item-container{
  padding-left: 40px;

}
.menu-info{

  .input-container{
    max-width: -webkit-fill-available;
    input:disabled{
      background: unset;
    }

    p{
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .css-b62m3t-container{
      max-width: -webkit-fill-available;
    }

    i.products{
      font-size: 1.2rem;
    }
  }
  .selector--is-disabled {
    width: 80px!important;
  }
}