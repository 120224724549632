@import "../../styles/globals";

.custom-cell {
  border: none;
  border-top: 1px solid #E6E8F0;
  border-left: 1px solid #E6E8F0;
  height: 100%;
  width: 100%;
}

.toolbar-container{
  padding: 24px;
  .col-12{
    margin-bottom: 12px;
    justify-content: center;
  }
  .col-lg-4:first-child{
    justify-content: flex-start;
  }
  .col-lg-4:last-child{
    justify-content: flex-end;
  }

  .col-lg-4,.col-12{
    display: flex;
    align-items: center;
  }
  .btn-back{
    margin-right: 12px;
  }
  .btn-next {
    margin-left: 12px;
  }
  .btn-back, .btn-next {
    display: inline-flex;
    background: #FFFFFF;
    border: 1px solid #E6E8F0;
    box-sizing: border-box;
    border-radius: 4px;
    width: 39px;
    height: 32px;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: rgb(34, 35, 47);
    -webkit-user-select: none;
    user-select: none;
    padding: 0 1rem;
  }
  .tab-switch{
    width: -webkit-fill-available;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  select{
    width: 100%;
    max-width: 200px;
  }
}

.rbc-month-view{
  border-left: none;
  border-right: none;
  border-color: $primary-border-color;

  .rbc-header{
    border-color: $primary-border-color;
  }
  .rbc-day-bg{
    border-color: $primary-border-color;
  }
  .rbc-today{
    background: $secondary-background-color;
  }
  .rbc-fasting{
    background: $red-fasting-days;
  }
  .rbc-off-range, .rbc-off-range-bg{
    background: $gray-shade-1;
  }
}

.option-location{
  display: flex;
  //justify-content: center;
  text-align: center;
  align-items: center;
  padding-top: 3px;
  margin-top: 5px;
}
.option-location img{
  max-height: 20px;
  width: unset;
  display: inline-flex;
  margin-right: 6px;
  margin-left: 6px;
}

.toolbar-container .selector__control .selector__value-container{
  overflow-y: hidden;
  text-overflow: unset;
  white-space: pre;
  display: flow-root;
  height: 38px;
  vertical-align: center;
  align-items: center;
  .selector__placeholder{
    margin-top: 8px;
}
}