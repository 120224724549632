@import "../../../../styles/globals";

.product_reception {
  table {
    min-width: 1000px;
    width: 100%;
    margin-bottom: 1.5em;
    border-spacing: 0;

    @media (min-width: 48em) {
      font-size: .9em;
    }

    @media (min-width: 62em) {
      font-size: 1em;
    }

    thead {
      // Accessibly hide <thead> on narrow viewports
      padding: 0;
      border: 0;

      // Unhide <thead> on wide viewports
      position: relative;
      clip: auto;
      height: auto;
      width: auto;
      overflow: auto;


      th {
        background-color: $primary-color;
        border: 1px solid $primary-border-color;
        font-weight: normal;
        text-align: center;
        color: white;

        &:first-of-type {
          text-align: left;
        }
      }
    }

    // Set these items to display: block for narrow viewports
    tbody,
    tr,
    th,
    td {
      display: block;
      padding: 0;
      text-align: left;
      white-space: normal;
    }

    tr {
      // Undo display: block
      display: table-row;

    }

    th,
    td {
      padding: .5em;
      vertical-align: middle;

      @media (min-width: 30em) {
        padding: .75em .5em;
      }
      display: table-cell;
      padding: .5em;


      @media (min-width: 62em) {
        padding: .75em .5em;
      }

      @media (min-width: 75em) {
        padding: .75em;
      }
    }

    caption {
      margin-bottom: 1em;
      font-size: 1em;
      font-weight: bold;
      text-align: center;

      @media (min-width: 48em) {
        font-size: 1.5em;
      }
    }

    tfoot {
      font-size: .8em;
      font-style: italic;

      @media (min-width: 62em) {
        font-size: .9em;
      }
    }

    tbody {
      // Undo display: block
      display: table-row-group;


      tr {
        margin-bottom: 1em;
        display: table-row;
        border-width: 1px;


        &:last-of-type {
          margin-bottom: 0;
        }
      }

      th[scope="row"] {
        border-left: 1px solid $primary-border-color;
        border-bottom: 1px solid $primary-border-color;
        background-color: transparent;
        color: rgba(0, 0, 0.87);
        text-align: center;
      }

      td {
        text-align: right;

        border-left: 1px solid $primary-border-color;
        border-bottom: 1px solid $primary-border-color;
        text-align: center;


        &:last-of-type {
          border-right: 1px solid $primary-border-color;

        }
      }
    }
  }
}


.responsive-table {

}
