@import "../../../../../styles/globals";

.add-new-item{
  cursor: pointer;
  font-weight: bold;
  color: $primary-color;
}
.selector-event-type .selector__control .selector__value-container{
  overflow-y: hidden;
  text-overflow: unset;
  white-space: pre;
  display: flow-root;
  height: 38px;
  vertical-align: center;
  align-items: center;
  .selector__placeholder{
    margin-top: 8px;
  }
  .selector__multi-value{
    display: inline-flex!important;
    margin: 6px 2px;
  }
}



.search-input-container{
  position: relative;
  min-width: 220px;

  i{
    position: absolute;
    top: 11px;
    left: 11px;
    color: $gray-color-1;
  }
  input{
    padding-left: 34px;
  }
}

.table.menus {
  .table-body, .table-head{
    min-width: 800px;
  }
}


@media (max-width: 576px) {
  .search-input-container {
    min-width: unset;
  }
  button{
    width: 100%;
    justify-content: center;
  }

}