@import "../../../styles/globals";

.container {
  .tag-container{
    border-radius: 12px;
    padding: 4px 10px;
    color: $primary-white;
    height: fit-content;
  }
}
.btn.w-100{
  width: 100%;
}

.container-calendar {
  .card {
    height: calc(100vh - 49px - 5.5rem - 2em);
    //   overflow-x: auto;

    .rbc-calendar {
      height: calc(100vh - 5.5rem - 49px - 2em);

      .rbc-time-view-cont {
        height: -webkit-fill-available;
        width: -webkit-fill-available;
        overflow-x: auto;

        .rbc-time-view {
          min-width: 850px;
        }
      }

      .rbc-month-view-cont {
        height: -webkit-fill-available;
        width: -webkit-fill-available;
        overflow-x: auto;

        .rbc-month-view {
          min-width: 850px;

          button {
            width: unset !important;
          }
        }
      }

      .rbc-time-view {
        //  min-width: 800px;
        overflow-x: auto;
      }
    }

    .btn-add-event {
      position: absolute;
      bottom: calc(2rem + 24px);
      right: calc(3rem + 20px);
      z-index: 7;
      width: unset !important;
    }

    .rbc-row-content .rbc-row:last-child, .rbc-row-segment {
      height: -webkit-fill-available;
    }
    .rbc-row-content {
      height: 100%!important;
    }

    .rbc-row-content .rbc-row:last-child {
      height: 100%;
    }

    .rbc-row-segment {
      overflow-y: auto;
      height: inherit;
      position: relative;
    }

    .rbc-row-segment::-webkit-scrollbar {
      display: none;
    }
  }

  .card.agenda {
    height: fit-content;
    min-height: calc(100vh - 5.5rem - 3.34em);
    position: relative;

    .rbc-calendar {
      min-height: calc(100vh - 5.5rem - 3.34em);
      height: fit-content;
    }
  }

  .rbc-row {
    max-height: calc(100% - 20px);
  }

  button.add-new-event {
    position: absolute;
    bottom: 8px;
    right: 8px;
    background: $primary-color;
    border: 1px solid $primary-color;
    border-radius: 8px;
    color: $primary-white;
    cursor: pointer;
    //opacity: 0.5;
  }

  button.add-new-event-2 {
    float: right;
    bottom: 8px;
    right: 8px;
    margin: 8px;
    background: $primary-color;
    border: 1px solid $primary-color;
    border-radius: 8px;
    color: $primary-white;
    cursor: pointer;
    // opacity: 0.5;
  }

  .css-b62m3t-container {
    width: 100%;
    /*z-index: 0;*/
  }

  .rbc-date-cell {
    background: $secondary-background-color;
    font-size: 0.8rem;

    .icon {
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
  }

  .rbc-event {
    border: none;
    padding: 4px 8px;
    width: inherit;
    margin: unset;

    &:focus {
      outline: none;
    }

    &.rbc-selected {
      background: none;
    }

    .rbc-event-label {
      display: none;
    }

    .rbc-event-content {
      width: -webkit-fill-available;


    }
  }
}

.border-grey-bottom {
  border-bottom: 1px solid $primary-border-color;
  margin-bottom: 16px;
}

.date-picker-container {
  display: inline-flex;
  justify-content: center;
}

.date-picker-container > :first-child {
  justify-content: center;
  display: inline-flex;
  /* div:first-child{
     justify-content: center;
   }*/
}
.rbc-time-view .rbc-allday-cell{
  display: none;
}
.rbc-header{
  border-bottom: none;
}
.rbc-time-view-cont{
  margin-top: -13px;
}
.occupation {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin: auto;
  margin-top: 10px;
}
.rbc-day-bg,.rbc-header{
  border: none!important;
}
.occupation.green {
  background-color: #45ccb1;
}
.occupation.red {
  background-color: #f66e84;
}
.occupation.yellow {
  background-color: #ffc241;
}
@media (max-width: 576px) {
  .container-calendar{
    .card {
      height: calc(100% - 22px - 1.6rem) !important;
      //height: calc(100vh -  187px)!important;
      .rbc-time-view {

        height: calc(100vh - 248px - 6.1rem);
      }
      padding-bottom: 24px;
    }
    .rbc-month-view {
      min-width: -webkit-fill-available!important;
      .rbc-row .rbc-date-cell {
        background: transparent;
        display: flex;
        justify-content: center;
        padding: 0;

        div.text-muted{
          display: none!important;
        }

      }
      .rbc-row .rbc-row-segment, .rbc-row.rbc-row-2{
        display: none;
      }
      .rbc-month-row{
        height: fit-content;
      }

      .rbc-row-content .rbc-row {
        height: -webkit-fill-available;
        max-height: 100%;
      }
    }
    .rbc-timeslot-group{
      min-height: 24px;
    }
  }
}
