@import "../../../styles/globals";

.notification-container{
  padding: 20px 64px 20px 20px;
  border-bottom: 1px solid $primary-border-color;
  margin-bottom: 4px;
  position: relative;
  i {
    margin-right: 20px;
    font-size: 1rem;
    color: $gray-color-1;
  }
  i.check{
    position: absolute;
    right:44px;
    top: 20px;
    color: green;
  }
  i.delete{
    position: absolute;
    right:20px;
    top: 20px;
    color: red;
  }
}

.notification-container.new{
  background: $secondary-background-color;
  border-radius: 10px;

}
.notification-container.done{
  background: $alert-success-background-color;
  border-radius: 10px;

}