@import "../../../styles/globals";

.month-container{
  cursor: pointer;
  border: 1px solid $primary-border-color;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  .month-events{
    text-align: center;
  }
}