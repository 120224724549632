@import "../../../../../../styles/globals";

.event-sheet{

  .content{
    padding: 12px;
  }
  .col-6,.col-12,.col-4,.col-3 {
    padding-bottom: 12px;

    &.bg-light{
      background: $gray-color-2;
    }
    &.bg-dark{
      background: $gray-shade-1;
    }
  }
  .border-grey-bottom{
    border-bottom: 1px solid $primary-border-color;
    margin-bottom: 16px;
  }
  .border-grey-top{
    border-top: 1px solid $primary-border-color;
    padding-top: 16px;
  }

  .header{
    cursor: pointer;
    display: inline-flex;

    i{
      align-self: center;
      margin-left: auto;
    }
  }


  .price-input-container{
    position: relative;
    min-width: 220px;
    p{
      position: absolute;
      top: -6px;
      right: 11px;
      color: $gray-color-1;
    }
    input{
      padding-right: 46px;
    }
  }
}

.event-sheet.client{
  .tab:hover{
    background: unset;
  }
  .tab.active:hover{
    background: $primary-white;
  }
}
.ReactModal__Content.subscription-limit.product-modal{
  height: 80vh;

  .content{
    max-height: calc(80vh - 120px);
  }
}

.product-icon-picker {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 260px;
  width: initial;
  border: 1px solid $primary-border-color;
  margin-bottom: 8px;
  justify-content: center;
  border-radius: 8px;
  padding: 12px;
  cursor: pointer;
  &.active {
    background: $secondary-background-color;
  }

  .product-icon {
    text-align: center;
    overflow: hidden;

    img {
      height: 240px;
      // height: 100%;
    }
    .title{
      text-align: center;
    }
  }
}