@import "../../../styles/globals";

.agenda-container{
  padding: 0 24px;
  height: fit-content;
  margin-top: -24px;

  .content{
    overflow-y: auto;
    height: inherit;
  }
  a:active, a:-webkit-any-link{
    color: $dark-color;
  }

  .table .table-body .table-row:hover {
    background: unset;
    .row:hover {
      background: $gray-shade-1;
    }
  }

  .table .table-body .table-row .row div{
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
  }
}