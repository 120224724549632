@import "../../../styles/globals";

.rbc-row-segment, .rbc-event{
  //border-radius: 20px;
  padding: 0;
  background: transparent;
}

.calendar-event{
  cursor: pointer;
  border-radius: 16px;
  color: black;
  font-weight: 400;
  font-size: 12px;
  overflow: hidden;
  height: inherit;

  .event {
    padding: 10px 16px;
    border-left: 2px;
    border-left-style: solid;
    border-radius: 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #081735;
    position: relative;
    height: inherit;

    .event-booked{
      position: absolute;
      right: -1.7rem;
      top: 0.6rem;
      transform: rotateY(0deg) rotate(40deg);
      background: $secondary-color;
      font-size: 0.50rem;
      width: fit-content;
      color: $primary-white;
      padding: 0 2rem;

    }
    .event-canceled{
      position: absolute;
      right: -1.7rem;
      top: 0.6rem;
      transform: rotateY(0deg) rotate(40deg);
      background: $alert-danger-font-color;
      font-size: 0.50rem;
      width: fit-content;
      color: $primary-white;
      padding: 0 2rem;

    }
  }
}