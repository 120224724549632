@import "../../../../styles/globals";

.package-products-container {
  min-width: 950px;
}


@media (max-width: 768px) {
  .package-products-container {
    min-width: 470px
  }
}