@import "../../../styles/globals";

.container.dashboard {

  a:active, a:-webkit-any-link {
    color: $dark-color;
  }

  .card {
    padding: 1rem;

  }

  .charts {

    .chart-bar {
      display: inline-flex;
      width: 70%;

      .div:first-child{
        width: 100%;
      }
    }

    .radial-bar {
      display: flex;
      width: 30%;
    }
  }

  .container-small{

    .right-container{
      text-align: right;

      .item-container{
        margin-top: 10px;
        font-size: 1.4rem;
        font-weight: 600;
      }
    }
  }

  .range-picker-cont{
   // position: absolute;
    right: 2rem;
    top: calc(50px + 1rem);
    .react-datepicker-wrapper{
      max-width: 220px;
      float: right;

      @media (max-width: 576px) {
        max-width: unset;
      }
    }

  }
  .react-datepicker{
    max-width: min-content;
  }

}
.react-datepicker{
  border-color: $dark-color;
  color: $dark-color!important;
  .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header,.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{

    color: $dark-color!important;
  }
  .react-datepicker__header{
    background: $secondary-background-color;
  }
  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range, .react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover,.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    background-color: $primary-color;
    color: $primary-white!important;
  }
  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
    background-color: $primary-shade-2;
    color: $primary-white!important;
  }
}