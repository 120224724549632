@import "../../../../../styles/globals";


.add-event {
  height: fit-content;
  min-height: calc(100vh - 6rem - 66px);

  .content {
    min-height: calc(100vh - 6rem - 146px);

    .location-icon-cont{
      display: flex;
      justify-content: center;
      vertical-align: center;
      height: 100%;
    }

    .location-icon {
      padding-right: 12px;
      padding-left: 12px;
      margin: 0 auto;
      margin-bottom: 20px;
      font-weight: bold;
      font-size: 20px;
      display: grid;
      align-items: end;
      justify-content: center;
      text-align: center;
      &.active {
        background: $gray-color-2;
      }
    }

    .location-icon img {
      width: 100%;
      max-width: 150px;
      height: unset;
    }

    .date-picker-container {
      justify-content: center;
      display: flex;

      div:first-child {
        width: -moz-fit-content;
        justify-content: center;
      }
    }
  }

  .row {
    justify-content: center;

    h1 {
      text-align: center;
    }
  }

  .tab-switch.with-content {
    border-radius: 8px;
    margin-bottom: 40px;
  }

  .checkbox {

    display: inline-flex;
    width: 100%;

    input, div {
      display: inline-flex;
      width: fit-content;
    }
  }

  @media (max-width: 768px) {
    button{
      width: 100%;
    }
  }

}


.exist-event-alert .content {
  color: $alert-danger-font-color-2;
  font-size: 1.3rem;
  font-weight: 600;
  text-align: center;
  vertical-align: center;
  height: -webkit-fill-available;
  display: flex;
  align-items: center;
}
